import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {withRouter} from 'react-router-dom';


const JiraIssueList = ({history, location, match}) => {
    const [issues, setIssues] = useState({B2B: [], CGC: [], BO: []});
    const [showNotification, setShowNotification] = useState(false);
    const [showErrorNotification, setShowErrorNotification] = useState(false);
    const projectKeys = ["B2B", "CGC", "BO", "DSHB", "PI", "DEVOP", "CHAIN", "ISEC", "CORE"];


    useEffect(() => {
        projectKeys.forEach(fetchIssues);

        const calls = projectKeys.map((project) => {
            return setInterval(() => {
                fetchIssues(project);
            }, 60000);
        });

        return () => {
            calls.forEach(clearInterval);
        }
    }, []);

    const fetchIssues = (project) => {
        axios.get(`/jira-issues/?project=${project}&active=true`)
            .then(response => {
                setIssues(prevIssues => ({
                    ...prevIssues,
                    [project]: response.data
                }));
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleCreateButtonClick = (jira_issue) => {
        axios.post('/deployments/', {
            jira_issue
        })
            .then(response => {
                console.log(response.data);
                setShowNotification(true);
            })
            .catch(error => {
                console.log(error);
                setShowErrorNotification(true);
            });
    }

    const handleCloseNotification = () => {
        setShowNotification(false);
    }
    const handleCloseErrorNotification = () => {
        setShowErrorNotification(false);
    }

    const allIssues = [].concat(...Object.values(issues));


    return (
        <div style={{overflow: 'scroll', width: '100%', padding: '16px 0 0 16px'}}>
            {showNotification && (
                <div className="notification is-success"
                     style={{position: 'fixed', top: '0', left: '0', right: '0', zIndex: '1000'}}>
                    <button class="delete" onClick={handleCloseNotification}></button>
                    Deploy workflow started!
                </div>
            )}
            {showErrorNotification && (
                <div className="notification is-danger"
                     style={{position: 'fixed', top: '0', left: '0', right: '0', zIndex: '1000'}}>
                    <button class="delete" onClick={handleCloseErrorNotification}></button>
                    Failed to start workflow!
                </div>
            )}
            <table className={"table is-hoverable is-fullwidth"} style={{backgroundColor: "#F5FBEF"}}>
                <thead>
                <tr>
                    <th>Issue Key</th>
                    <th>Status</th>
                    <th>Assignee</th>
                    <th>Summary</th>
                    <th>Deploy</th>

                </tr>
                </thead>
                <tbody>
                {allIssues.map(issue => (
                    <tr key={issue?.issue_key}>
                        <td onClick={() => console.log("Beleka")}>{issue?.issue_key}</td>
                        <td>{issue?.issue_status}</td>
                        <td>{issue?.issue_assignee_name}</td>
                        <td>{issue?.issue_summary}</td>
                        <td>
                            <button className="button is-primary"
                                    onClick={() => handleCreateButtonClick(issue?.id)}>
                                Deploy
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default withRouter(React.memo(JiraIssueList));
